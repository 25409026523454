import React from "react";

export default function Ourservices()
{
    return(
        <>
        <div className="container-fluid bgblack pt-160 mh-70vh ">
            <div className="container-fluid bgblack pt-160 mh-80vh studiobg">
            <div className="container text-center">
                <div className="row">
                    <div className="col-md-6 text-left">
                      <div>
                       <a href="https://www.urbanfit.studio" target="blank"><img src="assets/img/flex/studiologo.webp" /></a> 
                        <hr className="studioline"/>
                        <p className=" colorfff">The Urbanfit Studio is a fully integrated fitness facility that caters to a wide range of fitness requirements. Our extensive suite of health and fitness solutions is conveniently available in one location and is suitable for all levels of fitness proficiency. Our offerings include personal fitness training, functional training, a nutritionist clinic, a massage therapy center, and other resources that are designed to comprehensively address all of your fitness needs.</p>
                        <a href="https://www.urbanfit.studio" className="btn btn-light" target="blank">Visit Now</a>
                      </div>
                    </div>
                </div>
            </div>
            </div>
            <br/>
            <div className="container-fluid bgblack pt-160 mh-80vh storebg">
            <div className="container text-center">
                <div className="row">
                    <div className="col-md-6"></div>
                    <div className="col-md-6 text-left">
                      <div>
                       <a href="https://urbanfitstore.com/" target="blank"><img src="assets/img/flex/storelogo.webp" /></a> 
                        <hr className="storeline"/>
                        <p className=" colorfff">Urbanfit Store is a premium fitness supplement provider that offers both online and in-store services. We have several physical locations throughout Pune that enable us to deliver authentic products to our valued customers. With over 1000 high-quality products available, we can meet all your fitness needs. We take pride in featuring exclusive imported brands that are guaranteed to deliver outstanding results.</p>
                        <a href="https://urbanfitstore.com/" className="btn btn-light" target="blank">Visit Now</a>
                      </div>
                    </div>
                </div>
            </div>
            </div>
            <br/>
            <div className="container-fluid bgblack pt-160 mh-80vh edgebg">
            <div className="container text-center">
                <div className="row">
                   
                    <div className="col-md-6 text-left">
                      <div>
                        <img src="assets/img/flex/edgelogo.webp" />
                        <hr className="edgeline"/>
                        <p className=" colorfff">Urbanfit Edge is a leading athletic development center that offers a comprehensive range of training services designed to enhance speed, agility, and overall athletic performance. Our offerings include game-specific training, one-on-one goal-based training, dynamic strength training, plyometrics, and injury prevention and rehabilitation protocols. Additionally, we offer cardiovascular group activities, as well as biomechanics video analysis performed by our expert trainers.</p>
                        {/* <a href="http://urbanfitedge.com/" className="btn btn-light" target="blank">Visit Now</a> */}
                      </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
        </>
    )
}