import React from "react";
import { Outlet, Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
export default function Aboutus(props)
{
  const baseurlapi=props.baseurlapi;
  console.log(props)

  //  const baseurlapi="https://urbanfitapi.com/Websiteapi";

    const  Contact = ()=>
    {
     
      var name= document.getElementById('name').value;
      var number= document.getElementById('number').value;
      var email= document.getElementById('email').value;
      var message= document.getElementById('message').value;
    
      if(name=='')
        {
          swal({title: "",text: "Please fill out  name",icon: "error", });
        }
        else if(number=='')
        {
          swal({title: "",text: "Please fill out number ",icon: "error",});
        }
        else if(email=='')
        {
          swal({title: "",text: "Please fill out email ",icon: "error",});
        }
        else if(message=='')
        {
          swal({title: "",text: "Please fill out message",icon: "error",});
        }
        else
        {
              const Contactarr = new FormData(); 
              Contactarr.append("name",name);
              Contactarr.append("number", number);
              Contactarr.append("message",message);
              Contactarr.append("email", email);
              Contactarr.append("type", 'U');

              console.log(Contactarr)
          
              axios.post(baseurlapi+"/Contactus.php", Contactarr)
              .then(res => {

                  if(res.data.success=='1')
                  {
                    swal({title: "",text: res.data.message,icon: "success",}).then(function() {
                      window.location.reload(false);
                  });
                  }
                  else if(res.data.success=='2')
                  {
                    swal({title: "",text: res.data.message,icon: "error",});
                  } 
            });
  
     
      }
    }

    return(
        <>
       <div className="container-fluid bgblack pt-160">
            <div className="container-fluid">
                <div className="row">
                <div className="col-md-7 ">
                <div className="aboutpagebg aboutpagesection1">
                
                </div>
                </div>
                <div className="col-md-5">
                <div className="aboutpagetext">
                    <img src="assets/img/flex/icon1.webp"/>
                    <h1 className="colorfff fw-bold">Get Healthy & <br/> Fit<span className="color99ff33">.</span></h1>
                    <p className="colorfff">The existence of UrbanFit came in as a positioned supplement provider in the market. With time the umbrella spread with products and services related to every single aspect of health and fitness under one global platform.</p>
                    <p className="colorfff">UrbanFit is now re-purposed to solve every problem in the relatives of the health fragment, nourishment, and personal fitness industry by being a holistic service provider of the domain.</p>
                    <p className="colorfff">Knocking out troubles like "bad quality of service" combined with "bad quality of products provided by the unethical players," UrbanFit is working towards structuring the unorganized section and is committed to bringing in a more personalized experience with the lowest TAT for their service deliveries</p>
                </div>
                </div>
                </div>
           
           </div>
         </div>

         <div className="container-fluid bgblack pt-70 ">
            <div className="container text-center">
                <div className="row">
                <h1 className="color6223ff fw-bold">Our Fitness Solutions<span className="color99ff33">.</span></h1>
                <p className="colorfff " >Range of Fitness Solutions to meet every Health & Fitness need.</p>
                
                <div className="col-md-4">
                 <div>
                    <img src="assets/img/flex/icon2.webp"/>
                    <h6 className="color6223ff">Holistic Health <br/>Transformation</h6>
                    <p className="colorfff">Fitness Studio to Get in your Best<br/> Shape. Functional Training,<br/> Personal Trainer, HIIT/Tabata, and<br/> much more in one place.</p>
                 </div>
                </div>
                <div className="col-md-4">
                <div>
                    <img src="assets/img/flex/icon3.webp"/>
                    <h6 className="color6223ff">Therauptic Massage <br/>Center</h6>
                    <p className="colorfff">Life Changing and Relaxing <br/> massage therapy to relieve <br/> stress and promote a healthy <br/> lifestyle</p>
                 </div>
                </div>
                <div className="col-md-4">
                <div>
                    <img src="assets/img/flex/icon4.webp"/>
                    <h6 className="color6223ff">Quality Health<br/>Supplements</h6>
                    <p className="colorfff">Fuel your muscle with 100% <br/> Authentic Protein & Healthy <br/> Supplements.</p>
                 </div>
                </div>
                </div>
           
           </div>
         </div>
         <div className="container-fluid bgfff pt-70 ">
            <div className="container text-center">
                <div className="row">
                <h1 className="color6223ff fw-bold">The Team<span className="color99ff33">.</span></h1>
                <div className="row mt-5">
                <div className="col-md-2">
                 <div>
                    <img src="assets/img/flex/vinit.png" className="br-50p teamimage"/>
                    <h6 className="color6223ff mt-2">Vinit</h6>
                 </div>
                </div>
                <div className="col-md-2">
                 <div>
                    <img src="assets/img/flex/vidya.png" className="br-50p teamimage"/>
                    <h6 className="color6223ff mt-2">Vidya</h6>
                 </div>
                </div>
                <div className="col-md-2">
                 <div>
                    <img src="assets/img/flex/amruta.png" className="br-50p teamimage"/>
                    <h6 className="color6223ff mt-2">Amruta</h6>
                 </div>
                </div>
                <div className="col-md-2">
                 <div>
                    <img src="assets/img/flex/farukh.png" className="br-50p teamimage"/>
                    <h6 className="color6223ff mt-2">Farukh</h6>
                 </div>
                </div>
                <div className="col-md-2">
                 <div>
                    <img src="assets/img/flex/Sunil-Sir.webp" className="br-50p teamimage"/>
                    <h6 className="color6223ff mt-2">Sunil</h6>
                 </div>
                </div>
                <div className="col-md-2">
                 <div>
                    <img src="assets/img/flex/maithili.png" className="br-50p teamimage"/>
                    <h6 className="color6223ff mt-2">Maithili</h6>
                 </div>
                </div>
                
                </div>
                
                <div className="row mt-5">
                <div className="col-md-2">
                 <div>
                    <img src="assets/img/flex/nishad.png" className="br-50p teamimage"/>
                    <h6 className="color6223ff mt-2">Nishad</h6>
                 </div>
                </div>
                <div className="col-md-2">
                 <div>
                    <img src="assets/img/flex/parchit.png" className="br-50p teamimage"/>
                    <h6 className="color6223ff mt-2">Prachit</h6>
                 </div>
                </div>
                <div className="col-md-2">
                 <div>
                    <img src="assets/img/flex/Rishabh.png" className="br-50p teamimage"/>
                    <h6 className="color6223ff mt-2">Rishabh</h6>
                 </div>
                </div>
                <div className="col-md-2">
                 <div>
                    <img src="assets/img/flex/Rupali.png" className="br-50p teamimage"/>
                    <h6 className="color6223ff mt-2">Rupali</h6>
                 </div>
                </div>
                <div className="col-md-2">
                 <div>
                    <img src="assets/img/flex/Shashank.png" className="br-50p teamimage"/>
                    <h6 className="color6223ff mt-2">Shashank</h6>
                 </div>
                </div>
                <div className="col-md-2">
                 <div>
                    <img src="assets/img/flex/Shweta.png" className="br-50p teamimage"/>
                    <h6 className="color6223ff mt-2">Shweta</h6>
                 </div>
                </div>
                
                </div>
                </div>
           
           </div>
         </div>

        <div className="container-fluid bgfff pt-70  ">
            <div className="container text-center">
                <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-10 ">
                        <div className="text-center">
                            <h1 className="color6223ff fw-bold aboutsech1tag">We commit to staying aligned to our <br/> beliefs of removing the unawareness in <br/> the health industry<span className="color99ff33">.</span></h1>
                            <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#contactModal">Commit Today</button>
                            <br/>
                        </div>
                    </div>
                    <div className="col-md-1"></div>
                </div>
            </div>
            <br/>
        </div>

        {/* modal for contact form */}

           
<div className="modal fade" id="contactModal"  role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog2 modal-dialog-centered" role="document">
                <div className="modal-content" style={{backgroundColor: '#000'}}>
                <div className="modal-header">
                    <h5 className="modal-title text" id="exampleModalLongTitle">Contact Form</h5>
                    <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                <div className="container ">
                <h1 className="colorfff ">Let's Get Moving.</h1>
                <p className="colorfff ">Sign Up For Updates</p>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                        <label className="colorfff ">Full Name</label>
                        <input type="text" className="form-control text" id="name" aria-describedby="emailHelp" />
                        </div>
                        <br/>
                        <div className="form-group">
                        <label  className="colorfff ">Phone</label>
                        <input type="tel" className="form-control text" id="number" aria-describedby="emailHelp" />
                        </div>
                        <br/>
                        <div className="form-group">
                        <label  className="colorfff ">Email address</label>
                        <input type="email" className="form-control text" id="email" aria-describedby="emailHelp" />
                        </div>
                        <br/>
                        <div className="form-group">
                        <label  className="colorfff ">Type your message here...</label>
                        <input type="text" className="form-control text" id="message" aria-describedby="emailHelp" />
                        </div>
                       
                    </div>
                    
                </div>
            </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-primary" onClick={Contact}>Submit</button>
                </div>
                </div>
            </div>
            </div>

      
        </>
    )
}