import React from "react";
import Slider from "react-slick";
import Footer from "./Footer";
import Carousel from 'react-bootstrap/Carousel';
import { Outlet, Link } from "react-router-dom";
import axios from "axios";

import swal from "sweetalert";
export default function Home(props)
{
  const baseurlapi=props.baseurlapi;

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    autoplay: true,
    speed: 1000,
    

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          initialSlide: 4
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        }
      }
    ]
  };

  // const baseurlapi="https://urbanfitapi.com/Urbanfit_Wellness_API";

    const  Contact = ()=>
    {
     
      var name= document.getElementById('name').value;
      var number= document.getElementById('number').value;
      var email= document.getElementById('email').value;
      var message= document.getElementById('message').value;
    
      if(name=='')
        {
          swal({title: "",text: "Please fill out  name",icon: "error", });
        }
        else if(number=='')
        {
          swal({title: "",text: "Please fill out number ",icon: "error",});
        }
        else if(email=='')
        {
          swal({title: "",text: "Please fill out email ",icon: "error",});
        }
        else if(message=='')
        {
          swal({title: "",text: "Please fill out message",icon: "error",});
        }
        else
        {
              const Contactarr = new FormData(); 
              Contactarr.append("name",name);
              Contactarr.append("number", number);
              Contactarr.append("message",message);
              Contactarr.append("email", email);
              Contactarr.append("type", 'U');

              console.log(Contactarr)
          
              axios.post(baseurlapi+"/Contactus.php", Contactarr)
              .then(res => {

                  if(res.data.success=='1')
                  {
                    swal({title: "",text: res.data.message,icon: "success",}).then(function() {
                      window.location.reload(false);
                  });
                  }
                  else if(res.data.success=='2')
                  {
                    swal({title: "",text: res.data.message,icon: "error",});
                  } 
            });
  
     
      }
    }

    return(
        <>
       <div className="container-fluid bgblack mh-100vh">
       <div className="row"> 
        <div className="col-md-12">
         <video autoPlay muted loop id="myVideo">
          <source src="assets/video/file.mp4" type="video/mp4"/>
        </video>
        <div className="col-md-6 section02">
          <h2 className="colorfff" style={{fontSize:'50px'}}>Make Fitness your <br/> Ultimate Goal.</h2>
          <p className="colorfff ">Knocking out troubles like "bad quality of service" and "adulterated <br/> products," UrbanFit is now re-purposed to solve every problem in the <br/> relatives of the health fragment, nourishments, and personal fitness industry.</p>
        <button className="btn btn-primary btn1 bg6223ff " data-bs-toggle="modal" data-bs-target="#contactModal">Commit today</button> &nbsp;
        {/* <button className="btn btn-success btn1 bg99ff33 color000">Learn More</button> */}
        <Link to="/aboutus" className="btn btn-success btn1 bg99ff33 color000">Learn More</Link>
                              
        </div>
        <div className="col-md-6">

        </div>
       </div>
        </div>
       
       </div>

       <div className="container-fluid bgblack sectiondiv">
       <div className="row">
        <div className="col-md-6 ">
          <div className="aboutbg abousection1">
            
          </div>
        </div>
             <div className="col-md-6 text-left">
              <div className="abousection2">
              <h2 className="color99ff32 aos-init aos-animate" data-aos="fade-up" >About Us.</h2>
              <p className="colorfff aos-init aos-animate"  data-aos="fade-up">We are a fitness brand that welcomes everyone to achieve a well-being program and lead a healthier life at affordable prices. </p>
              <p className="colorfff aos-init aos-animate" data-aos="fade-up"> We make it easy to add different workouts that help in muscle and strength building, and toning into your daily routine which can be tailored to your needs with the help of digital classes and training that can be done from your living room as well as in the gym.</p>

              <p className="colorfff aos-init aos-animate" data-aos="fade-up"> We have got you covered with supplements, free workouts, group activities, healthy recipes, and wellbeing tips at the gym, App, and on social media.</p>

              <p className="colorfff aos-init aos-animate" data-aos="fade-up">  With easy access to an experienced set of gym and passionate trainers, you will have the full assistance you need to reach your goals in one place.</p>
            <br/>
              {/* <button className="btn btn-primary aos-init aos-animate bg6223ff" data-aos="fade-up">Read More</button> */}
              </div>
              </div>
             
         </div>
       </div>


       {/* services section  */}
       
       <div className="container-fluid bgblack sectiondiv">
        <div className="container">
        <div className="row">
        <div className="col-md-2"></div>
        <div className="col-md-8 ">
          <div className="text-center">
          <h2 className="color99ff32 aos-init aos-animate" data-aos="fade-up"> Our Services.</h2>
          <p className="colorfff aos-init aos-animate" data-aos="fade-up">Urban Fit offers a full range of group fitness activities like HIIT, Zumba, Kickboxing suitable for all levels of fitness lovers. We also provide personal training sessions at the studio at your convenience.</p>
          </div>
          </div>
        <div className="col-md-2"></div>
        <div className="container-fluid bgblack sectiondiv pt-70">
          <div className="container">
           
        
          <div className="row">
             
              <div className="col-md-12">
              <div className="row">
        <Carousel>
      <Carousel.Item className="servicesection" >
      <div  className="row">
                      <div className="col-md-4 pd-15">
                        <div >
                          <img src="assets/img/flex/img1.webp" style={{height:'315px',width:'100%',paddingRight: '20px'}} />
                        </div>
                        <div className="card pd-10 servicescard " style={{borderRadius:'7px 1px 7px 7px'}}>
                         <div className="servicesheader">
                           <h4 className="colorfff servicesh4">Personal Dieticians</h4>
                         </div>
                         
                          <p  className="colorblue servicesParagraph">Unlock the power of healthy eating with our expert personal dietitian. We'll work with you to create a customized meal plan for Weight Loss, Muscle Gain, PCOD, and much more.</p>
                        </div>
                        </div>
                        <div className="col-md-4 pd-15">
                        <div >
                          <img src="assets/img/flex/img2.webp" style={{height:'315px',width:'100%',paddingRight: '20px'}} />
                        </div>
                        <div className="card pd-10 servicescard" style={{borderRadius:'7px 1px 7px 7px'}}>
                        <div className="servicesheader">
                          <h4  className="colorfff servicesh4">Personal Trainer</h4>
                          </div>
                          <p  className="colorblue servicesParagraph">Get the support and motivation you need to reach your fitness goals with our personal training services. Get fit at home or Fitness Studio with one-to-one consultation.</p>
                        </div>
                        </div>
                        <div className="col-md-4 pd-15">
                        <div >
                          <img src="assets/img/flex/img3.webp" style={{height:'315px',width:'100%',paddingRight: '20px'}} />
                        </div>
                        <div className="card pd-10 servicescard" style={{borderRadius:'7px 1px 7px 7px'}}>
                        <div className="servicesheader">
                          <h4  className="colorfff servicesh4">Exercise Video</h4>
                          </div>
                          <p  className="colorblue servicesParagraph">Get fit for free with our workout video! Our expert trainers will guide you through a fun and effective workout that you can do in your own comfort.</p>
                        </div>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item  className="servicesection" >
                <div  className="row">
                <div className="col-md-4 pd-15"></div>
                                <div className="col-md-4 pd-15">
                                  <div >
                                    <img src="assets/img/flex/img4.webp" style={{height:'315px',width:'100%',paddingRight: '20px'}} />
                                  </div>
                                  <div className="card pd-10 servicescard" style={{borderRadius:'7px 1px 7px 7px'}}>
                                  <div className="servicesheader">
                                    <h4  className="colorfff servicesh4">Protein Supplements</h4>
                                    </div>
                                    <p  className="colorblue servicesParagraph">Buy 100% Authentic Protein & Healthy Supplements at the best price. Branded Supplements with Free Shipping and COD are available.</p>
                                  </div>
                                  </div>
                              </div>
                              <div className="col-md-4 pd-15"></div>
                </Carousel.Item>
              
    </Carousel>
        </div>
            
              </div>
            
            </div>
            
          </div>
         

        </div>
        </div>
        </div>
    
        </div>

      
      {/* our fitness section start here */}
      <div className="container-fluid bgblack sectiondiv pd-5">
        <div className="container pt-70">
        <div className="row">
          <div className="col-md-4">
          <h2 className="color99ff32 pt-70 aos-init aos-animate" data-aos="fade-up">  Health & Fitness at your <br/> Fingertips. </h2>
          <br/>
          <p className="colorfff aos-init aos-animate" data-aos="fade-up">Your Fitness Goals are now achievable with <br/> Fitness Apps. Personal Dietitian, Personal <br/> Trainer, Health Supplement, and much more for <br/> a Healthy Lifestyle.</p>
          <br/>
          <a href="https://play.google.com/store/apps/details?id=com.myproject.urbanfitapplication" target="blank"><img src="assets/img/flex/Google_play.webp"/></a>  
           <a href="https://apps.apple.com/in/app/urbanfit-the-fitness-app/id1533514450" target="blank"><img src="assets/img/flex/ios.webp"/></a>

          </div>
          
          <div className="col-md-8 fitnessbg fitnesssection">

          </div>
        </div>
      </div>
      </div>

      {/* product info section */}
<div className="container-fluid bgblack sectiondiv pt-70">
  
  <Carousel>
      <Carousel.Item className="testimonialcarousetext" >
      <div className="row">
      <div className="col-md-7 ">
       <img className="d-block w-100" src="assets/img/flex/img6.jpg" alt="First slide"/>
      </div>
      <div className="col-md-5 productinfottext">
       <h4 className="color99ff32 mt-20vh aos-init aos-animate" data-aos="fade-up">Premium & Sporty</h4>
       <br/>
       <p className="colorfff  aos-init aos-animate" data-aos="fade-up">Train in style with our high-performance <br/> sports and trendy wears</p>
       <br/>
       <span className="aos-init aos-animate text" data-aos="fade-up" style={{padding:'5px',fontSize:'20px'}}>Coming Soon</span>
       </div>
   </div>
      </Carousel.Item>
      <Carousel.Item className="testimonialcarousetext" >
      <div  className="row">
      <div className="col-md-7 ">
       <img className="d-block w-100" src="assets/img/flex/img7.jpg" alt="First slide"/>
      </div>
      <div className="col-md-5 productinfottext" >
      <img src="assets/img/flex/storelogo2.png"/>
       <h4 className="color99ff32 mt-10vh aos-init aos-animate" data-aos="fade-up">Feed your body with Right <br/> Nutrition.</h4>
       <br/>
       <p className="colorfff  aos-init aos-animate" data-aos="fade-up">Genuine Branded Proteins & Health <br/> Supplements at discounted prices.</p>
       <br/>
       {/* <button className="btn btn-primary aos-init aos-animate" data-aos="fade-up">Subscribe Now</button> */}
       <a href="https://urbanfitstore.com" className="btn btn-primary aos-init aos-animate" data-aos="fade-up" target="blank">Visit Now</a>
       </div>
     </div>
      </Carousel.Item>

      <Carousel.Item className="testimonialcarousetext" >
      <div  className="row">
      <div className="col-md-7 ">
       <img className="d-block w-100" src="assets/img/flex/img5.jpg" alt="First slide"/>
      </div>
      <div className="col-md-5 productinfottext" >
      <img src="assets/img/flex/studiologo2.png"/>
       <h4 className="color99ff32 mt-10vh aos-init aos-animate" data-aos="fade-up">Personalized Fitness</h4>
       <br/>
       <p className="colorfff  aos-init aos-animate" data-aos="fade-up">Train under Certified Expert Trainer to <br/> achieve  your fitness goals fast and easy</p>
       <br/>
       <a href="https://www.urbanfit.studio" className="btn btn-primary aos-init aos-animate" data-aos="fade-up" target="blank">Visit Now</a>
       
       </div>
     </div>
      </Carousel.Item>
     
    </Carousel>
  
</div>
  

{/* testimonial section start here */}

<div className="container-fluid bgblack sectiondiv pt-70">
  <div className="container testimonialsetion">
  <Carousel className="testimonialindector">
      <Carousel.Item  className="testimonialcarousetext" >
      <div  className="row">
      <div className="col-md-2 "></div>
      <div className="col-md-8  text-center">
        <h6 className="colorfff   aos-init aos-animate" data-aos="fade-up"><span className="testimonialheading">Priyanka</span></h6>
        <br/>
       <p className="colorfff  aos-init aos-animate" data-aos="fade-up">The studio is spacious and equipped with the latest equipment <br/> Fantastic place to workout.</p>
      </div>
       <div className="col-md-2 "></div>
   </div>
      </Carousel.Item>
      <Carousel.Item className="testimonialcarousetext" >
      <div  className="row">
      <div className="col-md-2 "></div>
      <div className="col-md-8 text-center">
      <h6 className="colorfff   aos-init aos-animate" data-aos="fade-up"><span className="testimonialheading">Suvidha Verma</span></h6>
      <br/>
       <p className="colorfff  aos-init aos-animate" data-aos="fade-up">The fitness trainers at Urban Fit Studio are knowledgeable, helpful  <br/> and provide amazing training without any doubt. Love the happy  <br/> vibe</p>
      </div>
       <div className="col-md-2 "></div>
   </div>
      </Carousel.Item>

      <Carousel.Item className="testimonialcarousetext" >
      <div  className="row">
      <div className="col-md-2 "></div>
      <div className="col-md-8 text-center">
        <h6 className="colorfff   aos-init aos-animate" data-aos="fade-up"><span className="testimonialheading">Harshad Shinde</span></h6>
        <br/>
       <p className="colorfff  aos-init aos-animate" data-aos="fade-up">Urban fit studio is truly an amazing place to start building a fit and <br/> healthy body. The studio has a very comfortable and positive vibes</p>
      </div>
       <div className="col-md-2 "></div>
   </div>
      </Carousel.Item>

      <Carousel.Item className="testimonialcarousetext" >
      <div  className="row">
      <div className="col-md-2 "></div>
      <div className="col-md-8 text-center">
        <h6 className="colorfff   aos-init aos-animate" data-aos="fade-up"><span className="testimonialheading">Raj k</span></h6>
        <br/>
       <p className="colorfff  aos-init aos-animate" data-aos="fade-up">The studio is good with a wide range of equipment in a good <br/> location. The staff is also polite and helpful</p>
      </div>
       <div className="col-md-2 "></div>
   </div>
      </Carousel.Item>

      <Carousel.Item className="testimonialcarousetext" >
      <div  className="row">
      <div className="col-md-2 "></div>
      <div className="col-md-8 text-center">
        <h6 className="colorfff   aos-init aos-animate" data-aos="fade-up"><span className="testimonialheading">Ajinkya</span></h6>
        <br/>
       <p className="colorfff  aos-init aos-animate" data-aos="fade-up">Amazing ambience, absolute cleanliness . I really enjoy the <br/> positive vibes in the studio</p>
      </div>
       <div className="col-md-2 "></div>
   </div>
      </Carousel.Item>

      <Carousel.Item className="testimonialcarousetext" >
      <div  className="row">
      <div className="col-md-2 "></div>
      <div className="col-md-8 text-center">
        <h6 className="colorfff   aos-init aos-animate" data-aos="fade-up"><span className="testimonialheading">Ajinkya</span></h6>
        <br/>
       <p className="colorfff  aos-init aos-animate" data-aos="fade-up">Amazing ambience, absolute cleanliness . I really enjoy the <br/> positive vibes in the studio</p>
      </div>
       <div className="col-md-2 "></div>
   </div>
      </Carousel.Item>

       <Carousel.Item className="testimonialcarousetext" >
       <div  className="row">
      <div className="col-md-2 "></div>
      <div className="col-md-8 text-center">
        <h6 className="colorfff  aos-init aos-animate" data-aos="fade-up"><span className="testimonialheading">Ria</span> </h6>
        <br/>
       <p className="colorfff  aos-init aos-animate" data-aos="fade-up">The gym equipment is top class and the staff is really warm and <br/>welcoming. Truly recommend</p>
      </div>
       <div className="col-md-2 "></div>
   </div>
      </Carousel.Item>
    </Carousel>
  </div>

</div>

{/* modal for contact form */}

           
<div className="modal fade" id="contactModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog2 modal-dialog-centered" role="document">
                <div className="modal-content" style={{backgroundColor: '#000'}}>
                <div className="modal-header">
                    <h5 className="modal-title text" id="exampleModalLongTitle">Contact Form</h5>
                    <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                <div className="container ">
                <h1 className="colorfff ">Let's Get Moving.</h1>
                <p className="colorfff ">Sign Up For Updates</p>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                        <label className="colorfff ">Full Name</label>
                        <input type="text" className="form-control text" id="name" aria-describedby="emailHelp" />
                        </div>
                        <br/>
                        <div className="form-group">
                        <label  className="colorfff ">Phone</label>
                        <input type="tel" className="form-control text" id="number" aria-describedby="emailHelp" />
                        </div>
                        <br/>
                        <div className="form-group">
                        <label  className="colorfff ">Email address</label>
                        <input type="email" className="form-control text" id="email" aria-describedby="emailHelp" />
                        </div>
                        <br/>
                        <div className="form-group">
                        <label  className="colorfff ">Type your message here...</label>
                        <input type="text" className="form-control text" id="message" aria-describedby="emailHelp" />
                        </div>
                       
                    </div>
                    
                </div>
            </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-primary" onClick={Contact}>Submit</button>
                </div>
                </div>
            </div>
            </div>


        </>
    )
}