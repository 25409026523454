import React from "react";
import { Outlet, Link } from "react-router-dom";
export default function Menubar()
{
    return(
        <>
            <header id="header" className="fixed-top header-transparent">
                <div className="container-fluid d-flex align-items-center justify-content-between">

                <h1  className="logo"><Link to="/"><img src="assets/img/flex/logo.png" className="headerlogo" /></Link></h1>
                
                <a href="index.html" className="logo"><img src="assets/img/logo.png" alt="" className="img-fluid" /></a>

                <nav id="navbar" className="navbar">
                    
                <img src="assets/img/flex/toggleicon.png" className="toggleimage" data-bs-toggle="modal" data-bs-target="#exampleModal" style={{cursor: 'pointer'}} />
                </nav>

                </div>
            </header>

      <Outlet />

      {/* <!--model start here --> */}

            <div className="modal  fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{width: '100%'}}>
            <div className="modal-dialog  modal-xl" style={{width: '500px',textAlign: 'center'}}>
                <div className="modal-content" style={{background: '#6223ff'}}>
                <div className="modal-header" style={{borderBottom: 'none'}}>
                
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{fontSize: '20px'}}></button>
                </div>
                <div className="modal-body">
                <div className="container-fluid">
                    <div className="row">
                        
                            
                        <div className="col-md-12">
                            <div className="koh-tab-content">
                            <div className="koh-tab-content-body">
                                <div className="">
                                <Link to="/" className="colorfff fs35 lh70 fw-bold navhover spanlable modalclose">Home</Link><br />   
                                   
                                </div>
                                <div className="">
                                    <Link to="/aboutus" className="colorfff fs35  navhover lh70 fw-bold spanlable modalclose">About-Us</Link>
                                </div>
                                <div className="">
                                <Link to="/ourservices" className="colorfff fs35 navhover lh70 fw-bold spanlable">Our Services</Link>
                                </div>
                                <div className="">
                              
                                <Link to="/contactus" className="colorfff fs35 navhover lh70 fw-bold spanlable modalclose">Contact us</Link>
                              
                                </div>
                                {/* <div className="koh-faq">
                                <div className="koh-faq-question litagmainlable">
                                <span className="koh-faq-question-span spanlable animate slide-left delay-15"> Resellers (form) </span><i className="fa fa-chevron-right animate slide-left delay-15" aria-hidden="true" style={{fontSize: '25px',marginLeft: '10px'}}></i>
                                </div>
                                <div className="koh-faq-answer litagsublable">
                                Resellers (form)
                                </div>
                                </div> */}
                                {/* <div className="koh-faq">
                                <div className="koh-faq-question litagmainlable">
                                <span className="koh-faq-question-span spanlable animate slide-left delay-16"> Product </span><i className="fa fa-chevron-right animate slide-left delay-6" aria-hidden="true" style={{fontSize: '25px',marginLeft: '10px'}}></i>
                                </div>
                                <div className="koh-faq-answer litagsublable">
                                Resellers (form)
                                </div>
                                </div> */}
                            </div>
                            </div>
                        <div style={{marginTop:'40vh'}}>
                        <a href="https://play.google.com/store/apps/details?id=com.myproject.urbanfitapplication" target="blank">
                            <img src="assets/img/flex/Google_play.webp"/></a>   
                            <a href="https://apps.apple.com/in/app/urbanfit-the-fitness-app/id1533514450" target="blank"><img src="assets/img/flex/ios.webp"/></a>

                        </div>

                        </div>
                    
                    </div> 
                </div> 
                </div>
               
                </div>
            </div>
            </div>


            
           

        </>
    )
}