import React from "react";

export default function Contactus()
{
    return(
        <>
         <div className="container-fuild mh-100vh bgblack">
        <div className="container pt-110">
        <div className="row">
        <div className="col-md-12">
         <h1 className="colorfff text-center">Contact Us</h1>
         <br/>
   

         <div className="container">
              <div className="row">
                    <div className="col-md-12">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15130.618825523137!2d73.91145!3d18.5444995!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c172f26df47d%3A0x8d508903a17ab0c1!2sUrbanFit%20Wellness%20Private%20Limited!5e0!3m2!1sen!2sin!4v1680783703881!5m2!1sen!2sin" width="100%" height="450" style={{border:'0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
          
         </div>
        
        </div>
       </div>
        </div>
    
       </div>
        </>
    )
}