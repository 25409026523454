import React from "react";


export default function Ourstory()
{
    return(
        <>
       <div className="container-fuild mh-100vh bgblack">
        <div className="container pt-110">
        <div className="row">
        <div className="col-md-12">
         <h1 className="colorfff text-center">Our Story</h1>
         <p className="colorfff">UF Flex was born out of a passion for fitness and a desire to create something that would inspire others to live healthier, more active lives. We saw a gap in the market for high-quality, stylish sportswear that not only looked great but also performed well. </p>
         <p className="colorfff">Our motivation came from our own experiences as athletes and fitness enthusiasts. We knew first-hand the importance of having the right gear for every activity, and we wanted to create a brand that would provide athletes with everything they needed to perform at their best. </p>
        <p className="colorfff">We were also inspired by the growing movement towards a healthier, more active lifestyle. We saw people all around us embracing fitness and pushing themselves to achieve their goals, and we wanted to be a part of that movement. </p>
        <p className="colorfff">Our vision for the company was to create a brand that would empower athletes of all levels to reach their full potential. We wanted to create sportswear that was not only functional but also stylish and fashionable so that athletes could look and feel their best while they worked out. </p>
       <p className="colorfff">We also wanted to create a community around our brand, where athletes could come together to share their stories, motivate each other, and support one another on their fitness journeys. We believe that by bringing people together around a shared passion for fitness, we can create a positive impact on the world and inspire more people to live healthier, more active lives. </p>
       <p className="colorfff">That is the heart and soul of UF Flex - a passion for fitness, a commitment to quality, and a vision for a healthier, more active world </p>
        </div>
       </div>
        </div>
    
       </div>
        </>
    )
}