import React from "react";

export default function Faq()
{
    return(
        <>
         <div className="container-fuild mh-100vh bgblack">
        <div className="container pt-110">
        <div className="row">
           
        <div className="col-md-12">
         <h1 className="colorfff text-center">FAQ</h1>
         <h4 className="colorfff">Order Placement & Delivery: </h4>
         <p className="colorfff mb-0"><span>1. </span> <span>How are orders placed on UF Flex delivered to me? </span></p>
         <p className="colorfff" style={{marginLeft:'30px'}}>Our courier partners deliver orders placed on UF Flex, and we give timely delivery.</p>
         <p className="colorfff mb-0"><span>2. </span> <span>How can I place an order on UF Flex? </span></p>
         <p className="colorfff" style={{marginLeft:'30px'}}>Find the product you want to buy <br/>

                Select your suitable size for the product<br/> 
                Add the product to your ‘shopping bag’ <br/>
                Go to your shopping bag and click on buy <br/>
                Provide Shipping Address <br/>
                Confirm payment and your order will be placed 
         </p>
         <p className="colorfff mb-0"><span>3. </span> <span>What is UF Flex's Shipping Policy?  </span></p>
         <p className="colorfff" style={{marginLeft:'30px'}}>UF Flex ships the product you ordered in the fastest time possible and delivers it to your doorstep. Please check the delivery availability with your PIN Code when ordering.</p>
        
         <p className="colorfff mb-0"><span>4. </span> <span>How can I check the status of my order?  </span></p>
         <p className="colorfff" style={{marginLeft:'30px'}}>Please tap on the "My Orders" section under the main menu to check your order status. </p>
        

         <h4 className="colorfff">Account & login: </h4>
         <p className="colorfff mb-0"><span>1. </span> <span>How can I create an account on UF Flex?  </span></p>
         <p className="colorfff" style={{marginLeft:'30px'}}>You can create your account on UF Flex using your mobile numberlkjkb4 </p>
         <p className="colorfff mb-0" style={{marginLeft:'30px'}}>1. Enter your Mobile Number by clicking Signup  </p>
         <p className="colorfff mb-0" style={{marginLeft:'30px'}}>2. Enter the OTP you get on your mobile.  </p>
         <p className="colorfff " style={{marginLeft:'30px'}}>You are ready to start shopping on UF Flex </p>
        
         <p className="colorfff mb-0"><span>2. </span> <span>How can I log in on UF Flex?   </span></p>
         <p className="colorfff" style={{marginLeft:'30px'}}>You can log in to UF Flex using your mobile number and OTP </p>
        
         <p className="colorfff mb-0"><span>3. </span> <span>What if I do not receive OTP on my mobile?   </span></p>
         <p className="colorfff" style={{marginLeft:'30px'}}>Please verify your network connectivity. You have the option to 'Resend OTP' which can be used to send a message again. </p>
          
         <h4 className="colorfff">Payment:  </h4>
         <h6 className="colorfff "> <span>How Can I pay for my Order at UF Flex?   </span></h6>
         <p className="colorfff"> <span>Payment can be made through any of the following modes:    </span></p>
         
         
         <p className="colorfff mb-0" style={{marginLeft:'30px'}}>1. Credit Card </p>

         <p className="colorfff mb-0" style={{marginLeft:'30px'}}> 2. Debit Card </p>

         <p className="colorfff mb-0" style={{marginLeft:'30px'}}>3. Net Banking</p> 

         <p className="colorfff mb-0" style={{marginLeft:'30px'}}>4. Wallet </p>

         <p className="colorfff mb-0" style={{marginLeft:'30px'}}>5. UPI Payment </p>

         <p className="colorfff " style={{marginLeft:'30px'}}>6. Cash on Delivery (COD) </p>
         <br/>
         <h5 className="colorfff">What should I do if my payment fails? </h5>
         <p className="colorfff">In case of payment failure, it's advisable to first verify the account details entered, billing address and password/OTP used for payment. After ensuring the accuracy of these factors, retry the payment. If the payment still fails, you can opt for Cash on Delivery (COD), provided it's available in your area. However, if the COD option is unavailable, you may try placing the order at a later time. If you encounter any difficulties, feel free to contact the customer support team for further assistance. </p>
 
          </div>
       </div>
        </div>
    
       </div>
        </>
    )
}
