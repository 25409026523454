import React from "react";
import axios from "axios";

import swal from "sweetalert";
export default function Footer(props)
{
    const baseurlapi=props.baseurlapi;
    const  Contact = ()=>
    {
     
      var name= document.getElementById('name').value;
      var number= document.getElementById('number').value;
      var email= document.getElementById('email').value;
      var message= document.getElementById('message').value;
    
      if(name=='')
        {
          swal({title: "",text: "Please fill out  name",icon: "error", });
        }
        else if(number=='')
        {
          swal({title: "",text: "Please fill out number ",icon: "error",});
        }
        else if(email=='')
        {
          swal({title: "",text: "Please fill out email ",icon: "error",});
        }
        else if(message=='')
        {
          swal({title: "",text: "Please fill out message",icon: "error",});
        }
        else
        {
              const Contactarr = new FormData(); 
              Contactarr.append("name",name);
              Contactarr.append("number", number);
              Contactarr.append("message",message);
              Contactarr.append("email", email);
              Contactarr.append("type", 'U');

              console.log(Contactarr)
          
              axios.post(baseurlapi+"/Contactus.php", Contactarr)
              .then(res => {

                  if(res.data.success=='1')
                  {
                    swal({title: "",text: res.data.message,icon: "success",}).then(function() {
                      window.location.reload(false);
                  });
                  }
                  else if(res.data.success=='2')
                  {
                    swal({title: "",text: res.data.message,icon: "error",});
                  } 
            });
  
     
      }
    }
    return(
        <>
        <div className="container-fluid bg5e3ad4 sectiondiv pt-70 mh-85vh">
        <div className="container ">
                <h1 className="colorfff ">Let's Get Moving.</h1>
                <p className="colorfff ">Sign Up For Updates</p>
                <div className="row">
                    <div className="col-md-4">
                        <div className="form-group">
                        <label className="colorfff ">Full Name</label>
                        <input type="text" className="form-control" id="name" aria-describedby="emailHelp" />
                        </div>
                        <br/>
                        <div className="form-group">
                        <label  className="colorfff ">Phone</label>
                        <input type="tel" className="form-control" id="number" aria-describedby="emailHelp" />
                        </div>
                        <br/>
                        <div className="form-group">
                        <label  className="colorfff ">Email address</label>
                        <input type="email" className="form-control" id="email" aria-describedby="emailHelp" />
                        </div>
                        <br/>
                        <div className="form-group">
                        <label  className="colorfff ">Type your message here...</label>
                        <input type="text" className="form-control" id="message" aria-describedby="emailHelp" />
                        </div>
                        <br/>
                        <button type="submit" className="btn btn-primary bg99ff33 color000" onClick={Contact}>Submit</button>
                    </div>
                    <div className="col-md-3">
                    </div>
                    <div className="col-md-5">
                        <div>
                            <p className="colorfff ">We’re here to help and answer any questions you <br/> might have. We will answer your inquiries in a <br/> maximum of 48 hours.</p>
                            <hr className="hrtag"/>
                        </div>
                        <div>
                            <p className="colorfff ">Tel: +91 8799952724</p>
                            <p className="colorfff ">Email: info@urbanfitwellness.com</p>
                            <p className="colorfff fw-bold ">Reach Us : OFFICE NO. 1 D, 2nd Floor, Cerebrum IT Park,<br/> Kalyani Nagar, Pune - 411014</p>
                        </div>
                        <div className="text-right ">
                            <a href="https://www.youtube.com/channel/UCc2AlPjw2t90wykXWMhF4xA" target="blank"><img src="assets/img/flex/youtube.webp"/></a>&nbsp;&nbsp;
                            <a href="https://www.instagram.com/urbanfitwellness/" target="blank"><img src="assets/img/flex/instagram.webp"/></a>&nbsp;&nbsp;
                            <a href="https://www.facebook.com/urbanfitwellnesspvtltd/" target="blank"><img src="assets/img/flex/facebook.webp"/></a>
                            <a href="https://twitter.com/urbanfit_app" target="blank"><img src="assets/img/flex/Twitter.png" style={{height:'34px',marginLeft:'10px'}} /></a>
                        </div>
                    </div>
                </div>
            </div>
            <br/>
           
            <p className="text-center colorfff bgblack pd-10" >© 2018-24 by UrbanFit Wellness Pvt Ltd. Design and developed by <a href="https://www.nurasoftech.com/Best_Web_Development_Company_in_Pune.php" target="_blank"  class="footerurl">Nura Softech Pvt Ltd.</a></p>
            </div>
           
        </>

    )
}