import ReactDOM from "react-dom/client";
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from './components/Home';
import Menubar from "./components/Menubar";
import Ourstory from "./components/Ourstory";
import Faq from "./components/Faq";
import Contactus from "./components/Contactus";
import Footer from "./components/Footer";
import Aboutus from "./components/Aboutus";
import Ourservices from "./components/Ourservices";

function App() {

  const baseurlapi="https://urbanfitapi.com/Urbanfit_Wellness_API";
  return (
    <>
     <BrowserRouter>
    
    <Routes>
   
      <Route path="/" element={<Menubar />}>
        <Route  index element={<Home baseurlapi={baseurlapi} />}  />
        <Route path="/contactus" element={<Contactus baseurlapi={baseurlapi} />}  />
        <Route path="/ourstory" element={<Ourstory baseurlapi={baseurlapi} />}  />
        <Route path="/faq" element={<Faq />} />
        <Route path="/aboutus"   element={<Aboutus baseurlapi={baseurlapi} />}  />
        <Route path="/ourservices" element={<Ourservices baseurlapi={baseurlapi} />}  />

        
        </Route>
     </Routes>
     
  </BrowserRouter>
  <Footer baseurlapi={baseurlapi}/>
    </>
   
  );
}

export default App;
